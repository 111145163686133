import pickBy from 'lodash/pickBy';
import querystring from 'query-string';

import { ERentalTypeFilter } from '@/components/route/search/RentalTypeFilter/RentalTypeFilter';
import { defaultLocale, TCurrency } from '@/config/locales';
import { ECampsiteType } from '@/constants/amenitiesFilter';
import { ESearchFilters } from '@/constants/searchFilters';
import { RentalSearch } from '@/graphql/types-and-hooks';
import { ISearchFormFilters } from '@/redux/modules/searchForm';
import { TRootState } from '@/redux/rootReducer';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { PriceType } from '@/redux/selectors/listing/page';
import { ERentalType } from '@/services/analytics/types';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { ECampsiteCategoryType, IData, ITag } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';
import { formatMeasurement } from '@/utility/measurements';
import { mapPluralUnitToSingular } from '@/utility/units';

import { getCampsiteTypeDisplay, getStayTypeDisplay, getVehicleTypeDisplay } from './rentals';

interface IFavorite {
  added_at: string;
  id: number;
  rental_id: number;
}

export interface IPrice {
  currency?: TCurrency;
  discount: number;
  instantBook: boolean;
  originalPrice: number;
  currentPrice: number;
  overrideDayPrice?: number;
  discountFromOverrideDayPrice?: number;
  period: PriceType;
}

export interface IRentalDetails {
  classText?: string;
  feature?: string;
  feet?: string;
  sleeps?: string;
  beds?: string;
  maxVehicleLength?: string;
  seats?: string;
  infos?: string[];
  location?: string;
  ratingNumber?: number;
  ratingQtd?: number;
  year?: string;
  tags?: ITag[];
}
export interface IRentalTile {
  campground?: false;
  currency?: TCurrency;
  details?: IRentalDetails;
  description?: string;
  id?: number;
  rental_id?: number;
  instantBook?: boolean;
  isFavorite?: boolean;
  isPinned?: boolean;
  isStay?: boolean;
  isCampsite?: boolean;
  campsiteCategoryType?: string;
  linkUrl?: string;
  loading?: boolean;
  photoUrl?: string | string[];
  photoAlt?: string;
  title?: string;
  unavailable?: boolean;
  price?: IPrice;
  blendLogicSource?: string | null;
  displaySiteCategoryType?: string;
  siteCategoryType?: string;
}
export interface ICampgroundPrice {
  currency?: TCurrency;
  lowestPrice: number;
}

export interface ICampgroundTileDetails {
  ratingNumber?: number;
  ratingQtd?: number;
  location?: string;
}

export interface ICampgroundTile {
  campground: true;
  isFavorite?: boolean;
  details?: ICampgroundTileDetails;
  currency?: TCurrency;
  description?: string;
  id?: number;
  linkUrl?: string;
  loading?: boolean;
  sitesDisplayText?: string;
  photoUrl?: string | string[];
  photoAlt?: string | undefined;
  title?: string;
  price?: ICampgroundPrice;
}

export type ISearchResultTile = ICampgroundTile | IRentalTile;

export const isCampgroundTile = (tile: ISearchResultTile): tile is ICampgroundTile => {
  return 'campground' in tile && !!tile.campground;
};
export const isCampsiteTile = (tile: ISearchResultTile): tile is ICampgroundTile => {
  return 'isCampsite' in tile && !!tile.isCampsite;
};

export const mapCampgroundToPrice = (campground: ICampgroundData): ICampgroundPrice => {
  const lowestPrice = campground.lowest_price;
  const currency = campground.presentment_currency || defaultLocale.base_currency;
  return {
    currency,
    lowestPrice,
  };
};

export const mapCampgroundTileToRentalPrice = (campground: ICampgroundTile): IPrice => {
  return {
    currency: campground.currency,
    discount: 0,
    instantBook: false,
    currentPrice: campground.price?.lowestPrice || 0,
    originalPrice: campground.price?.lowestPrice || 0,
    period: PriceType.NIGHT,
  };
};

const getCampgroundSitesDisplayText = (campground: ICampgroundData): string => {
  const counts = campground.total_category_counts;
  if (!counts) return '';
  const intl = getIntl();
  const rvCount = counts[ECampsiteType.RV_SITE];
  const tentCount = counts[ECampsiteType.TENT_SITE];
  const lodgingCount = counts[ECampsiteType.LODGING_SITE];
  const countsText = [];
  if (rvCount > 0) {
    countsText.push(
      intl.formatMessage(
        { defaultMessage: '{rvCount, plural, one {# RV site} other {# RV sites}}' },
        { rvCount },
      ),
    );
  }
  if (tentCount > 0) {
    countsText.push(
      intl.formatMessage(
        { defaultMessage: '{tentCount, plural, one {# Tent site} other {# Tent sites}}' },
        { tentCount },
      ),
    );
  }
  if (lodgingCount > 0) {
    countsText.push(
      intl.formatMessage({ defaultMessage: '{lodgingCount} Lodging' }, { lodgingCount }),
    );
  }

  return countsText.join(', ');
};

const getCampgroundLocationText = (campground: ICampgroundData): string => {
  const intl = getIntl();

  if (!campground.location) {
    return '';
  }

  const location = `${campground.location?.city}${
    campground.location?.state && `, ${campground.location?.state}`
  }`;

  return intl.formatMessage({ defaultMessage: 'Campground in {location}' }, { location });
};

type TMapCampgroundToTileInput = {
  campground: ICampgroundData;
  loading?: boolean;
  hasImageCarousel?: boolean;
  queryParams?: TRootState['queryParams'];
  searchMeta?: TRootState['search']['meta'];
};
export const mapCampgroundToTile = ({
  campground,
  loading,
  hasImageCarousel,
  queryParams,
}: TMapCampgroundToTileInput): ICampgroundTile => {
  const images = campground.images ? campground.images.map(image => image.url) : [];
  const imagesLimit = 7;
  const primaryImage = campground.primary_image_url || images[0];

  const queriesToCarryOver = [
    ESearchFilters.ADDRESS,
    ESearchFilters.DATE_FROM,
    ESearchFilters.DATE_TO,
    ESearchFilters.FLEXIBLE_DAYS,
    ESearchFilters.GUESTS_ADULTS,
    ESearchFilters.GUESTS_CHILDREN,
    ESearchFilters.GUESTS_INFANTS,
    ESearchFilters.FILTER_FEATURE,
    ESearchFilters.CAMPGROUND_FEATURES,
    ESearchFilters.CAMPSITE_RV_SITE_FEATURES,
    ESearchFilters.CAMPSITE_TENT_SITE_FEATURES,
    ESearchFilters.CAMPSITE_LODGING_FEATURES,
    ESearchFilters.CAMPSITE_MAX_VEHICLE_LENGTH,
    ESearchFilters.CAMPSITE_SUPPORTED_SUBTYPES,
    ESearchFilters.CAMPSITE_BATHROOMS,
    ESearchFilters.CAMPSITE_BEDROOMS,
    ESearchFilters.CAMPSITE_BEDS,
    ESearchFilters.CAMPSITE_CATEGORY_TYPES,
    ESearchFilters.PRICE_MIN,
    ESearchFilters.PRICE_MAX,
    'discount_code',
  ] as string[];
  const carryOverQuery =
    queryParams &&
    Object.keys(queryParams)
      .filter(key => queriesToCarryOver.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: queryParams[key] }), {});
  const query: any = queryParams
    ? pickBy({
        ...carryOverQuery,
        from: queryParams[ESearchFilters.DATE_FROM],
        to: queryParams[ESearchFilters.DATE_TO],
      })
    : undefined;

  const linkUrl = `${campground.slug}${query ? `?${querystring.stringify(query)}` : ''}`;

  return {
    campground: true,
    currency: campground.presentment_currency,
    id: campground.id,
    linkUrl,
    photoUrl: hasImageCarousel && images.length > 1 ? images.slice(0, imagesLimit) : primaryImage,
    title: campground.name,
    loading,
    details: {
      ratingNumber: campground.score,
      ratingQtd: campground.reviews_num,
      location: getCampgroundLocationText(campground),
    },
    sitesDisplayText: getCampgroundSitesDisplayText(campground),
    price: mapCampgroundToPrice(campground),
  };
};

type TMapCampgroundsToTilesInput = {
  campgrounds: ICampgroundData[];
  loading?: boolean;
  hasImageCarousel?: boolean;
  queryParams?: TRootState['queryParams'];
  searchMeta?: TRootState['search']['meta'];
  searchForm?: TRootState['searchForm'];
};
export const mapCampgroundsToTiles = ({
  campgrounds,
  loading,
  hasImageCarousel,
  queryParams,
  searchMeta,
}: TMapCampgroundsToTilesInput): ICampgroundTile[] =>
  campgrounds?.map(campground =>
    mapCampgroundToTile({
      campground,
      loading,
      hasImageCarousel,
      queryParams,
      searchMeta,
    }),
  ) || [];

export const mapRentalToPrice = ({ rental }: { rental?: IData | null }) => {
  if (!rental) return undefined;

  const currency = rental.presentment_currency || defaultLocale.base_currency;
  const period = rental?.active_options?.use_day_pricing ? PriceType.DAY : PriceType.NIGHT;
  const currentPrice =
    rental?.active_options?.day_price || rental?.price_per_day || rental.active_price?.day || 0;
  const originalPrice = mapRentalToOriginalPrice(rental);
  const discount =
    Math.round(
      ((originalPrice && currentPrice && 1 - currentPrice / originalPrice) || 0 + Number.EPSILON) *
        100,
    ) || 0;

  return {
    currency,
    discount,
    instantBook: rental.active_options?.instant_book ?? rental.instant_book,
    currentPrice,
    originalPrice,
    period,
  };
};

export const mapRentalToOriginalPrice = (rental: IData | RentalSearch | null) => {
  return rental?.price_per_day || rental?.active_price?.day || 0;
};

type TMapRental = {
  rental: IData;
  favorites: ReturnType<typeof getUserFavorites>;
  loading?: boolean;
  hasImageCarousel?: boolean;
  queryParams?: TRootState['queryParams'];
  searchMeta?: TRootState['search']['meta'];
  searchForm?: TRootState['searchForm'];
};
export const mapRentalToTile = ({
  rental,
  favorites,
  loading,
  hasImageCarousel,
  queryParams,
  searchForm,
}: TMapRental): IRentalTile => {
  const intl = getIntl();

  const near_rental = queryParams && queryParams[ESearchFilters.NEAR_RENTAL];
  const isCampsite = rental.rental_category === ERentalType.CAMPGROUND;
  // Treat campsite tiles as stay tiles for most of the logic
  const isStay = rental.rental_category === ERentalType.STAY || isCampsite;
  const isPinned = near_rental === String(rental.id);
  const firstTag = (!!rental.tags?.length && rental.tags[0]?.tag) || undefined;
  const pinMessage = isStay
    ? intl.formatMessage({
        defaultMessage: 'The stay you selected',
        description: 'Search results: pinned listing feature tag',
      })
    : intl.formatMessage({
        defaultMessage: 'The rig you selected',
        description: 'Search results: pinned listing feature tag',
      });
  const feature = isPinned ? pinMessage : firstTag;

  const rentalSleeps =
    rental.campsite_category?.max_occupancy || rental.stay?.sleeps || rental.sleeps;
  const sleeps = rentalSleeps
    ? intl.formatMessage({ defaultMessage: 'Sleeps {sleeps}' }, { sleeps: rentalSleeps })
    : undefined;

  const rentalBeds = rental.campsite_category?.beds || rental.stay?.beds;
  const beds = rentalBeds
    ? intl.formatMessage(
        { defaultMessage: '{total, plural, one {# Bed} other {# Beds}}' },
        { total: rentalBeds },
      )
    : undefined;

  const year = rental.vehicle ? rental.vehicle?.year : rental.vehicle_year;

  const seats = rental.seatbelts
    ? intl.formatMessage({ defaultMessage: 'Seats {seats}' }, { seats: rental.seatbelts })
    : undefined;
  const lengthUnit = mapPluralUnitToSingular(rental.locale.length_unit);

  const vehicleLength = rental.vehicle?.length ? rental.vehicle?.length : rental.vehicle_length;

  const feet =
    isStay || (lengthUnit as string) === ''
      ? ''
      : formatMeasurement(vehicleLength, {
          unit: lengthUnit,
        });

  const maxVehicleLength =
    rental.rental_category === ERentalTypeFilter.CAMPGROUND &&
    rental.campsite_category?.category_type === ECampsiteCategoryType.RV_SITE &&
    rental.campsite_category?.max_vehicle_length
      ? intl.formatMessage(
          { defaultMessage: 'RVs up to {length}{lengthUnit}' },
          {
            length: rental.campsite_category.max_vehicle_length,
            lengthUnit: 'ft', // TODO: backend to allow for Locale and meters
          },
        )
      : undefined;

  const isFavorite =
    favorites && favorites.data.some((favorite: IFavorite) => favorite.rental_id === rental.id);

  const images = rental.images ? rental.images.map(rental => rental.url) : [];
  const imagesLimit = 7;
  const primaryImage = rental.primary_image_url || images[0];

  const queriesToCarryOver = [
    ESearchFilters.ADDRESS,
    ESearchFilters.DATE_FROM,
    ESearchFilters.DATE_TO,
    ESearchFilters.FLEXIBLE_DAYS,
    ESearchFilters.GUESTS_ADULTS,
    ESearchFilters.GUESTS_CHILDREN,
    ESearchFilters.GUESTS_INFANTS,
    ESearchFilters.FILTER_FEATURE,
    ESearchFilters.FILTER_TYPE,
    ESearchFilters.PRICE_MIN,
    ESearchFilters.PRICE_MAX,
    'discount_code',
  ] as string[];
  const carryOverQuery =
    queryParams &&
    Object.keys(queryParams)
      .filter(key => queriesToCarryOver.includes(key))
      .reduce((acc, key) => ({ ...acc, [key]: queryParams[key] }), {});
  const query: any = queryParams
    ? pickBy({
        ...carryOverQuery,
        from: queryParams[ESearchFilters.DATE_FROM],
        to: queryParams[ESearchFilters.DATE_TO],
      })
    : undefined;

  [
    ESearchFilters.DELIVERY,
    ESearchFilters.DELIVERY_STATIONARY,
    ESearchFilters.DELIVERY_CENTER,
    ESearchFilters.DELIVERY_LOCATION_ID,
    ESearchFilters.DELIVERY_QUERY,
    ESearchFilters.DELIVERY_DETAILS,
    ESearchFilters.SEARCH_DELIVERY_CAMPGROUND_ID,
  ].forEach((queryParam: any) => {
    if (
      (searchForm && searchForm.filters[queryParam as keyof ISearchFormFilters]) ||
      (queryParams && queryParams[queryParam])
    ) {
      query[queryParam] =
        (searchForm && searchForm.filters[queryParam as keyof ISearchFormFilters]) ||
        (queryParams && queryParams[queryParam]);
    }
  });

  const linkUrl = `${rental.slug}${query ? `?${querystring.stringify(query)}` : ''}`;

  const location = rental.location
    ? `${rental.location?.city}${rental.location?.state && `, ${rental.location?.state}`}`
    : `${rental?.home?.city}${rental?.home?.state && `, ${rental?.home?.state}`}`;

  const classText = (() => {
    if (isCampsite) return getCampsiteTypeDisplay(rental.campsite_category?.display_category_type);
    if (isStay) return getStayTypeDisplay(rental.stay?.display_stay_type);
    return getVehicleTypeDisplay(rental.display_vehicle_type);
  })();

  const photoAlt =
    isCampsite || isStay
      ? undefined
      : `${year} ${rental?.vehicle_make} ${rental?.vehicle_model} ${classText} rental in ${location}`;

  return {
    currency: rental.presentment_currency,
    id: rental.id,
    instantBook: rental.active_options?.instant_book ?? rental.instant_book,
    unavailable: rental.unavailable,
    isFavorite: isFavorite,
    isPinned,
    isStay,
    isCampsite,
    campsiteCategoryType: isCampsite ? rental.campsite_category?.category_type : undefined,
    linkUrl,
    photoUrl: hasImageCarousel && images.length > 1 ? images.slice(0, imagesLimit) : primaryImage,
    photoAlt,
    title: rental.name,
    loading,
    details: {
      ratingNumber: rental.score,
      ratingQtd: rental.reviews_num ?? rental.score_count,
      location:
        rental.rental_category === ERentalType.CAMPGROUND
          ? intl.formatMessage({ defaultMessage: 'Campground in {location}' }, { location })
          : location,
      feature,
      year: `${year}`,
      classText,
      feet,
      sleeps,
      beds,
      maxVehicleLength,
      seats,
      infos: [sleeps, seats].filter(attr => Boolean(attr)) as string[],
      tags: rental.tags,
    },
    price: mapRentalToPrice({ rental }),
    blendLogicSource: rental.blend_logic_source || null,
    displaySiteCategoryType: rental.campsite_category?.display_category_type,
    siteCategoryType: rental.campsite_category?.category_type,
  };
};

type TMapRentals = {
  rentals: IData[];
  favorites: ReturnType<typeof getUserFavorites>;
  loading?: boolean;
  hasImageCarousel?: boolean;
  queryParams?: TRootState['queryParams'];
  searchMeta?: TRootState['search']['meta'];
  searchForm?: TRootState['searchForm'];
};
export const mapRentalsToTiles = ({
  rentals,
  favorites,
  loading,
  hasImageCarousel,
  queryParams,
  searchMeta,
  searchForm,
}: TMapRentals): IRentalTile[] =>
  rentals?.map(rental =>
    mapRentalToTile({
      rental,
      favorites,
      loading,
      hasImageCarousel,
      queryParams,
      searchMeta,
      searchForm,
    }),
  ) || [];
