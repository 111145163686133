export enum OptimizelyFlags {
  LISTING_ORDER = 'listing_module_order_web',
  CAMPSITE_LISTING_ORDER = 'campsite_listing_module_order_web',
  SEARCH_SORT_LOGIC = 'search_sort_logic_web',
  TRIP_DETAILS_ORDER = 'trip_details_module_order_web',
  ACTIVITY_LOG = 'trip_details_activity_log',
  SEARCH_MODULE_CATEGORIES_WEB = 'search_module_categories_web',
  HOMEPAGE_CUSTOM_BANNER_WEB = 'homepage_custom_banner_web',
  INTERIOR_DAMAGE_UP_FUNNEL = 'interior_damage_up_funnel',
  FREE_CANCELLATION_PERIOD = 'free_cancellation_period',
  INCREASE_REVIEW_RATE = 'increase_full_review_rate',
  SYSTEM_DOWNTIME = 'system_downtime',
  TRIP_DETAILS_BANNER = 'trip_details_banner',
  BLOG_BANNER = 'blog_banner',
  LISTINGS_NEARBY_THRESHOLD_WEB = 'listings_nearby_threshold_web',
  INSURANCE_BUNDLE_SELECTION_MODAL = 'insurance_bundle_selection_modal',
  CAMPGROUNDS_SEARCH_TAB_AND_HOME_TAB = 'campgrounds_web',
  REDESIGNED_CAMPGROUND_PAGES_CLAIMED = 'redesigned_campground_pages__claimed_',
  USM_FOR_GUIDE_PAGES = 'usm_for_guide_pages',
  USM_INSTANT_BOOK = 'usm_instant_book',
  USM_AUTO_APPLY_INSTANT_BOOK = 'usm_auto_apply_instant_book',
  ADDITIONAL_FILTERS_HOME_SEARCH = 'additional_filters_home_search',
  STAYS_FULL_ROLLOUT = 'stays_full_rollout',
  NEARBY_CAMPGROUNDS_WEB = 'nearby_campgrounds_web',
  MAP_LAYERS = 'map_layers',
  STAY_CAMPGROUND_FEATURE_FILTER = 'stay_campground_feature_filter',
  ROAMLY_WEATHER = 'roamly_weather',
  CAMPGROUND_DATE_CHANGE = 'campground_date_change_ui',
  ODN_TILE_TEST = 'odn_tile_test',
  RV_STAYS_DELIVERY_WEB = 'rv_stays_delivery_web',
  CAPTAIN_BLOG_AI_ENABLED = 'captain_blog_ai_enabled',
  ROAMLY_WEATHER_PRE_PURCHASE = 'roamly_weather_pre_purchase',
  SINGLE_TILE_GRID = 'single_tile_grid',
  CAMPGROUND_DISCOUNTING_BANNER = 'campgrounds_discounting_banner',
  GUIDED_SEARCH_ON_HOME_SCREEN = 'web_guided_search_on_home_screen',
  NEW_DELIVERY_SEARCH_FILTER = 'new_delivery_search_filter',
  OUTDOORSY_STAYS = 'outdoorsy_stays',
  REFER_A_FRIEND_WEB = 'refer_a_friend_web',
  ATTENTIVE_ENABLED = 'attentive_enabled',
}

export enum EAlgoSlugVariationKey {
  weekender = 'weekend_availability',
}

export enum EABExperimentVariationKey {
  ENABLED_A = 'enableda',
  ENABLED_B = 'enabledb',
  CONTROL = 'control',
}

export enum EReviewsCompletionVariationKey {
  ENABLED = 'enable_review_skip_function',
  DISABLED = 'disable_review_skip_function',
}

export enum EInsuranceBundleModalVariationKey {
  PER_DAY_PRICING = 'per_day_pricing',
}
