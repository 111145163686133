import { Icon, Text } from '@outdoorsyco/bonfire';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { formatDateRange } from '@/utility/format-date';

import { INavbarSearch } from '../../SearchHeader';
interface IProps {
  address: INavbarSearch['address'];
  dates: INavbarSearch['dates'];
  guests: INavbarSearch['guests'];
  onOpenMobileSearch?: () => void;
  isCampgroundSearch?: boolean;
}

const MobileSearchMobileButton: React.FC<IProps> = ({
  address,
  dates,
  guests,
  onOpenMobileSearch,
  isCampgroundSearch = false,
}) => {
  const intl = useIntl();

  const addressButtonLabel = address
    ? address
    : intl.formatMessage({
        defaultMessage: 'Where are you heading?',
        description: 'Global Header > Placeholder text for location button on mobile bar',
      });

  const formattedDates = useMemo<string | null>(() => {
    if (!dates?.from || !dates?.to) {
      return null;
    }
    const datesLabel = formatDateRange(dates.from.toString(), dates.to.toString());

    if (dates.flexible_days) {
      return `${datesLabel} (±${dates.flexible_days})`;
    }

    return datesLabel;
  }, [dates]);

  const totalGuests = (guests?.adults || 0) + (guests?.children || 0) + (guests?.infants || 0) || 0;

  const guestsLabel = intl.formatMessage(
    {
      defaultMessage: '{totalGuests, plural, one {# guest} other {# guests}}',
      description: 'Global Header > label for guests button on mobile bar',
    },
    {
      totalGuests,
    },
  );

  const datesButtonLabel = formattedDates
    ? formattedDates
    : intl.formatMessage({
        defaultMessage: 'Add dates',
        description: 'Global Header > Placeholder text for dates button on mobile bar',
      });

  const guestsButtonLabel = totalGuests
    ? guestsLabel
    : intl.formatMessage({
        defaultMessage: 'Add guests',
        description: 'Global Header > Placeholder text for guests button on mobile bar',
      });

  return (
    <div
      className={
        'border grid grid-flow-col h-12 bg-white rounded-full overflow-hidden border-neutral-20'
      }>
      <button className="flex items-center px-4" onClick={onOpenMobileSearch}>
        <Icon name="General.Search" className="mr-[10px]" />

        <div className="flex flex-col items-start">
          {!isCampgroundSearch && (
            <Text className="text-sm font-medium text-left truncate w-[240px]">
              {addressButtonLabel}
            </Text>
          )}
          <Text className="flex items-center text-xs text-gray-50 gap-2">
            {datesButtonLabel}
            <span className="rounded-full w-0.5 h-0.5 bg-[#4F5B69]" />
            {guestsButtonLabel}
          </Text>
        </div>
      </button>
    </div>
  );
};

export default MobileSearchMobileButton;
